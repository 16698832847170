// consumer_orderNo_center_order_getOrderQuantity
const __request = require(`./__request/__request_contentType_json`);
const consumer_orderNo_center_order_getOrderQuantity = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/orderNo/center/order/getOrderQuantity',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_orderNo_center_order_getOrderQuantity;