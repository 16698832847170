import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/pagination/index.vue'
import moment from 'moment'

import consumer_orderNo_center_order_getCenterList from '@/lib/data-service/haolv-default/consumer_orderNo_center_order_getCenterList'
import consumer_orderNo_center_order_getOrderQuantity from '@/lib/data-service/haolv-default/consumer_orderNo_center_order_getOrderQuantity'
import consumer_orderNo_center_order_download from '@/lib/data-service/haolv-default/consumer_orderNo_center_order_download'
import consumer_orderNo_center_userAllowBookingDepts from '@/lib/data-service/haolv-default/consumer_orderNo_center_userAllowBookingDepts'

export default {
    data() {
        return {
            loading: false,
            searchForm: {

                endDateTime: '',
                startDateTime: '',
                deptIds: [], //
                deptIdList: [],
                psgName: '', // 出行人姓名
                orderNo: '',   // 订单号
                orderStatus: 0,

                currentPage: 1,   //当前页码
                pageSize: 10,   //每页记录条数
            },
            applyTime: [],
            deptList: [],
            totalCount: 0,   //总条数

            activeName: '0',
            tabList: [
                // // 订单状态  10=预订，11=待支付，12=已支付，13=超标审批中，21=待出票，22=出票成功，23=出票失败，24=作废，90=已取消
                {
                    name: "0",
                    label: "全部",
                    value: 0
                },
                {
                    name: "13",
                    label: "超标审批中",
                    value: 0
                },
                {
                    name: "11",
                    label: "待支付",
                    value: 0
                },
                {
                    name: "21",
                    label: "待出票",
                    value: 0
                },
                {
                    name: "22",
                    label: "已出票",
                    value: 0
                },
                {
                    name: "23",
                    label: "出票失败",
                    value: 0
                },
                {
                    name: "90",
                    label: "已取消",
                    value: 0
                }
            ],
            orderList: [],
            canDownload: true,
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.getDeptList();
        this.getOrderList()
        this.getListNum()
    },
    watch: {
        applyTime: {
            handler (val) {
                if (val && val.length) {
                    this.searchForm.startDateTime = moment(val[0]).format('YYYY-MM-DD 00:00:00')
                    this.searchForm.endDateTime = moment(val[1]).format('YYYY-MM-DD 23:59:59')
                } else {
                    this.searchForm.startDateTime = ''
                    this.searchForm.endDateTime = ''
                }
            }
        },
    },
    methods: {
        changeDept() {
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
        },
        // 获取部门信息
        getDeptList() {
            consumer_orderNo_center_userAllowBookingDepts().then(res => {
                this.deptList = res.datas.deptList;
            })
        },
        // 获取订单列表
        getOrderList() {
            this.loading = true
            this.searchForm.orderStatus = parseInt(this.activeName)
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
            consumer_orderNo_center_order_getCenterList(this.searchForm).then(res => {
                let orderList = res.datas.list
                orderList.forEach(value => {
                    let psgNameList = []
                    if (value.psgName) {
                        psgNameList = value.psgName.split(',')
                    }
                    value.psgNameList = psgNameList
                })
                this.orderList = orderList
                this.totalCount = res.datas.totalCount
            }).catch(err => {
                this.orderList = []
                this.totalCount = 0
            }).finally(() => {
                this.loading = false
            })
        },
        getListNum() {
            consumer_orderNo_center_order_getOrderQuantity(this.searchForm).then(res => {
                const flightOrderNum = res.datas.flightOrderNum
                this.tabList.forEach(value => {
                    let isHaveNum = false
                    flightOrderNum.forEach(value1 => {
                        if (value1.status === parseInt(value.name)) {
                            isHaveNum = true
                            value.value = value1.orderNum
                        }
                    })
                    if (!isHaveNum) {
                        value.value = 0
                    }
                })
            })
        },
        // 搜索
        onSearch() {
            console.log(this.searchForm);
            this.searchForm.currentPage = 1
            this.getOrderList()
            this.getListNum()
        },
        // 重置
        onReset() {
            this.searchForm = {
                endDateTime: '',
                startDateTime: '',
                deptIds: [], //
                deptIdList: [],
                psgName: '', // 出行人姓名
                orderNo: '',   // 订单号
                orderStatus: 0,

                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            }
            this.applyTime = []
            this.onSearch()
        },
        // 订单状态
        getStatusToClass(val) {
            // 订单状态  10=预订，11=待支付，12=已支付，13=超标审批中，21=待出票，22=出票成功，23=出票失败，24=作废，90=已取消
            val = parseInt(val)
            if (val === 10 || val === 11 || val === 12 || val === 13 || val === 21) {
                return 'yellow-text'
            } else if (val === 22) {
                return 'green-text'
            } else {
                return 'red-text'
            }
        },
        // 查看详情
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-order-center-flight-order-detail',
                query: {
                    orderNo: val.orderNo,
                    type: 'orderCenter'
                }
            })
        },
        downloadOrderExcel() {
            if (!this.canDownload) {
                return
            }
            this.canDownload = false
            consumer_orderNo_center_order_download(this.searchForm).then(res => {
                let url = res.datas.orderDetailsUrl
                console.log('url', url)
                if (!url && url === '') {
                    this.$message({
                        type: "warning",
                        message: '下载失败，请稍候再试'
                    })
                    return
                }
            console.log('111')
                window.open(url, "_blank");
            }).finally(() => {
                this.canDownload = true
            })
        },
    }
}
